@charset "UTF-8";
/*=============================================
=            Global import file               =
=============================================*/
/**
 * ⚠️ Please, you should import ONLY SCSS variables in this file ⚠️
 * - Custom CSS Properties should be imported in `src/global/styles/default.scss`
 */
/**
 * ⚠️ The following variables are only used on _utilities.scss ⚠️
 * (the intention is to easy the process of iterating amount multiple values)
 */
/*=============================================
=       Grid system mixin and utilities       =
=============================================*/
/*=============================================
=    Breakpoint viewport media queries        =
=============================================*/
/*=============================================
=         Breakpoint viewport sizes          =
=============================================*/
/*=============================================
=         Gris utilities and helpers          =
=============================================*/
/* stylelint-disable @stylistic/indentation */
/*=============================================
=            Utility CSS Generator            =
=============================================*/
/**
 * ℹ️ This file will generate all CSS helper classes (with various options)
 *    that a developer can use in their front-end app
 */
/**
 * ⚠️ Please, you should import ONLY SCSS variables in this file ⚠️
 * - Custom CSS Properties should be imported in `src/global/styles/default.scss`
 */
/**
 * ⚠️ The following variables are only used on _utilities.scss ⚠️
 * (the intention is to easy the process of iterating amount multiple values)
 */
@font-face {
  font-family: "Champions Regular";
  font-display: swap;
  src: url("https://panenka.uefa.com/panenka/fonts/champions/champions-regular.woff2") format("woff2"), url("https://panenka.uefa.com/panenka/fonts/champions/champions-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Champions Regular Cyrillic";
  font-display: swap;
  src: url("https://panenka.uefa.com/panenka/fonts/champions/champions-regular-cy.woff2") format("woff2"), url("https://panenka.uefa.com/panenka/fonts/champions/champions-regular-cy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Champions Bold";
  font-display: swap;
  src: url("https://panenka.uefa.com/panenka/fonts/champions/champions-bold.woff2") format("woff2"), url("https://panenka.uefa.com/panenka/fonts/champions/champions-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champions Bold Cyrillic";
  font-display: swap;
  src: url("https://panenka.uefa.com/panenka/fonts/champions/champions-bold-cy.woff2") format("woff2"), url("https://panenka.uefa.com/panenka/fonts/champions/champions-bold-cy.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Champions Display";
  font-display: swap;
  src: url("https://panenka.uefa.com/panenka/fonts/champions/champions-display.woff2") format("woff2"), url("https://panenka.uefa.com/panenka/fonts/champions/champions-display.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
[pk-competition=ucl], .pk-competition--ucl {
  --pk-font-comp-bold: "Champions Bold", system-ui, sans-serif;
  --pk-font-comp-display: "Champions Display", system-ui, sans-serif;
  --pk-font-comp-regular: "Champions Regular", system-ui, sans-serif;
}

/*=============================================
=            Color Palette            =
=============================================*/
[pk-competition=ucl], .pk-competition--ucl {
  --pk-primary-01--light: #00004b;
  --pk-primary-02--light: #61619f;
  --pk-primary-03--light: #363683;
  --pk-primary-04--light: #010141;
  --pk-secondary-01--light: #0d3aff;
  --pk-secondary-02--light: #0929c9;
  --pk-secondary-03--light: #061da5;
  --pk-secondary-04--light: #041181;
  --pk-tertiary-01--light: #5af7dc;
  --pk-tertiary-02--light: #a0fbeb;
  --pk-tertiary-03--light: #74f6e0;
  --pk-tertiary-04--light: #22e3c2;
  --pk-accent-01--light: #ff16ff;
  --pk-accent-02--light: #fc84fc;
  --pk-accent-03--light: #cc05cc;
  --pk-accent-alt-01--light: #ffffff;
  --pk-accent-alt-02--light: #ffffff;
  --pk-accent-alt-03--light: #ffffff;
  --pk-interaction--light: #0232ff;
  --pk-interaction-compl--light: #ffffff;
  --pk-interaction-high-contrast--light: #ffffff;
  --pk-text-01--light: #1a313c;
  --pk-text-02--light: #455c67;
  --pk-text-03--light: #637a85;
  --pk-text-04--light: #ffffff;
  --pk-text-05-light: rgb(44, 44, 44);
  --pk-ui-00--light: #ffffff;
  --pk-ui-01--light: #e8eaf3;
  --pk-ui-02--light: #dbdde9;
  --pk-ui-03--light: #c1c2d5;
  --pk-ui-04--light: #a3a4b7;
  --pk-ui-05--light: #252663;
  --pk-ui-06--light: #0a0a2f;
  --pk-data-01--light: #0232ff;
  --pk-data-02--light: #00e4eb;
  --pk-data-03--light: #ff51a2;
  --pk-data-04--light: #9a00ff;
  --pk-background--light: #f1f3f8;
  --pk-elevation-01--light: #ffffff;
  --pk-elevation-02--light: #ffffff;
  --pk-elevation-03--light: #ffffff;
  --pk-support-01--light: #32a72c;
  --pk-support-01a--light: #7ccb70;
  --pk-support-01b--light: #aef5a2;
  --pk-support-02--light: #0085e5;
  --pk-support-02a--light: #35b5ec;
  --pk-support-02b--light: #c1ecff;
  --pk-support-03--light: #df6817;
  --pk-support-03a--light: #d68d45;
  --pk-support-03b--light: #f8d5bd;
  --pk-support-04--light: #cb333b;
  --pk-support-04a--light: #d96464;
  --pk-support-04b--light: #f1c8ca;
  --pk-support-05--light: #ffcd44;
  --pk-support-05a--light: #ffd970;
  --pk-support-05b--light: #ffedbb;
  --pk-primary-01--dark: #00004b;
  --pk-primary-02--dark: #61619f;
  --pk-primary-03--dark: #363683;
  --pk-primary-04--dark: #010141;
  --pk-secondary-01--dark: #0d3aff;
  --pk-secondary-02--dark: #0929c9;
  --pk-secondary-03--dark: #061da5;
  --pk-secondary-04--dark: #041181;
  --pk-tertiary-01--dark: #5af7dc;
  --pk-tertiary-02--dark: #a0fbeb;
  --pk-tertiary-03--dark: #74f6e0;
  --pk-tertiary-04--dark: #22e3c2;
  --pk-accent-01--dark: #ff16ff;
  --pk-accent-02--dark: #fc84fc;
  --pk-accent-03--dark: #cc05cc;
  --pk-accent-alt-01--dark: #ffffff;
  --pk-accent-alt-02--dark: #ffffff;
  --pk-accent-alt-03--dark: #ffffff;
  --pk-interaction--dark: #00eeff;
  --pk-interaction-compl--dark: #00004b;
  --pk-interaction-high-contrast--dark: #ffffff;
  --pk-text-01--dark: #ffffff;
  --pk-text-02--dark: rgba(255, 255, 255, 0.7);
  --pk-text-03--dark: rgba(255, 255, 255, 0.5);
  --pk-text-04--dark: rgb(255, 255, 255);
  --pk-text-05--dark: #000041;
  --pk-ui-00--dark: rgba(255, 255, 255, 0.05);
  --pk-ui-01--dark: rgba(255, 255, 255, 0.1);
  --pk-ui-02--dark: rgba(255, 255, 255, 0.25);
  --pk-ui-03--dark: rgba(255, 255, 255, 0.35);
  --pk-ui-04--dark: rgba(255, 255, 255, 0.55);
  --pk-ui-05--dark: rgba(255, 255, 255, 0.8);
  --pk-ui-06--dark: #ffffff;
  --pk-data-01--dark: #00eeff;
  --pk-data-02--dark: #ffffff;
  --pk-data-03--dark: #ff51a2;
  --pk-data-04--dark: #385dff;
  --pk-background--dark: rgb(0, 0, 64);
  --pk-elevation-01--dark: rgb(10, 10, 97);
  --pk-elevation-02--dark: rgb(21, 21, 115);
  --pk-elevation-03--dark: rgb(23, 23, 122);
  --pk-support-01--dark: #32a72c;
  --pk-support-01a--dark: #7ccb70;
  --pk-support-01b--dark: #cffecd;
  --pk-support-02--dark: #0085e5;
  --pk-support-02a--dark: #35b5ec;
  --pk-support-02b--dark: #c1ecff;
  --pk-support-03--dark: #df6817;
  --pk-support-03a--dark: #d68d45;
  --pk-support-03b--dark: #f8d5bd;
  --pk-support-04--dark: #cb333b;
  --pk-support-04a--dark: #d96464;
  --pk-support-04b--dark: #f1c8ca;
  --pk-support-05--dark: #ffcd44;
  --pk-support-05a--dark: #ffd970;
  --pk-support-05b--dark: #ffedbb;
}